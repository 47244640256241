#authors {
  a {
    text-decoration: none;
    border: none;
    &:hover {
      color: $info;
    }
  }
  li {
    font-size: 18px;
  }
}
