#post {
  a {
    text-decoration: none;
    border: none;

    &:hover {
      color: $info;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: $primary;
    padding-top: 10px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 12px 12px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: 12px 12px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: 12px 12px 3px rgba(34, 25, 25, 0.4);
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 100%;
    height: auto;
  }

  /******************************
   * STYLES FOR DISPLAYING CODE
   ******************************/
  // heading style for a code section
  h5 {
    background: none repeat scroll 0% 0% $code-heading-background;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    margin-bottom: 0px;
    border-radius: 3px 3px 0px 0px;
  }

  // main code section style
  .highlight {
    border-radius: 8px;

    pre {
      font-size: 18px;
      padding: 10px;

      code {
        color: $code-text-color;
      }
    }
  }

  //tocbot beging
  .is-active-link {
    color: $primary;
  }

  .is-active-link::before {
    background-color: $primary
  }
  //tocbot end
}

blockquote {
  margin: 20px 0;
  padding: 10px 20px;
  border-left: 5px solid $blockquote-border-color;
  background-color: $blockquote-background-color;
  font-style: italic;
  color: $blockquote-text-color;
  border-radius: 3px 3px 0px 0px;
}
