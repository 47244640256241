.navbar {
  box-shadow: 1px 1px 3px $primary;
  opacity: 0.8;
  background: $white;

 .nav-item {
    font-size: 1.4rem;
    padding-right: 20px;
  }
}

.nav-link {
  color: $info !important;

  &:hover {
    color: $primary !important;
  }
}

#tag {
  font-size: 1rem;
  color: $info;
  background-color: $primary;
  margin-right: 5px;
  margin-bottom: 5px;

  &:hover {
    color: $primary !important;
    background-color: $info !important;
  }

  &:hover b {
      color: $info !important;
      background-color: $white-tags !important;
    }

  b {
    color: $white-tags;
    background-color: $info;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.active {
  color: $primary !important;
  background-color: $info !important;
  
  b {
    color: $info !important;
    background-color: $white-tags !important;
  }
}
