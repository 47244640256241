$primary: #37b349;
$info: #070707;

$code-heading-background: #dededc;
$code-text-color: #b6b6b6;

$blockquote-border-color: #e74c3c;;
$blockquote-background-color: #2c3e50;
$blockquote-text-color: #ecf0f1;

$white-tags: #f2f2f2;
